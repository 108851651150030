.landing {
  min-height: 100vh;
  background-color: var(--primary-color);
  background-image: url("../../assets/png/desktop_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}


.landing__container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
}

.landing__left {
  flex: 0.7;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;

}

.landing__header {
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 700;
  font-size: 4rem;
  color: #FFFFFF;
}

.landing__btn {
  outline: none;
  padding: 12px 25px;
  border: 2px solid #FFFFFF;
  color: #FFFFFF;
  border-radius: 10px;
  text-align: center;
  background: transparent;
  font-family: var(--primary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 1.15rem;
  cursor: pointer;
}

.landing__right {
  flex: 0.3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: 'Rubik';
  font-style: normal;
  margin-top: 2rem;
}

.landing__right h1 {
  font-weight: 700;
  font-size: 8rem;  
  color: #FFFFFF;
  opacity: 0.3;
  transform: rotate(-90deg);
}

.landing__countdown {
  display: flex;
  gap: 2rem;
  margin-top: auto;
  position: absolute;
  bottom: 5%;
  left: 10%;
  padding: 1.5rem;
  background: linear-gradient(104.15deg, rgba(255, 205, 0, 0.06) 33.5%, rgba(255, 255, 255, 0) 89.64%);
  border-radius: 20px;
}

.countdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  font-family: var(--primary-font);
  color: #ffffff;
}

.countdown h2 {
  font-weight: 600;
  font-size: 1.5rem;
}
.countdown h4 {
  font-weight: 300;
  font-size: 1.15rem;
}



@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {
  .landing__container {
    width: 90%;
  }
  .landing__header {
    font-size: 3.75rem;
  }
  .landing__right h1 {
    font-size: 7rem;  
  }
  .landing__countdown {
    left: 5%;
}
}

@media only screen and (max-width: 1200px) {
  .landing__header {
    font-size: 3.5rem;
  }
  .landing__right h1 {
    font-size: 6rem;  
  }
}

@media only screen and (max-width: 1100px) {
  .landing__header {
    font-size: 3rem;
  }
  .landing__right h1 {
    font-size: 5rem;  
  }
}

@media screen and (max-width: 992px) {
  .landing__container {
    width: 100%;
    padding: 2rem;
  }
  .landing__header {
    font-size: 2.5rem;
  }
  .landing__countdown {
    gap: 1.5rem;
    bottom: 5%;
    left: 0%;
    padding: 1.5rem;
    margin: 0 1rem;
  }
}

@media screen and (max-width: 800px) {
  .landing__right {
    display: none; 
  }
  .landing__left {
    width: 100%;
    flex: 1;
  }
  
  .countdown h2 {
    font-weight: 600;
    font-size: 1.35rem;
  }
  .countdown h4 {
    font-weight: 300;
    font-size: 0.95rem;
  }
}

@media screen and (max-width: 600px) {

}

@media screen and (max-width: 400px) {

}